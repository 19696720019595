import React, { useState } from 'react';
import Checkbox from '../../../common/checkbox';
import { useDispatch } from 'react-redux';
import { getSummaryReducer } from '../../../../redux/selectors/summaryReducer.selectors';
import {
  toggleAgreementsCheckbox,
  toggleAllAgreementsCheckboxes,
} from '../../../../redux/actions/summaryActions';
import { getProfileDetails } from '../../../../redux/selectors/profileSelector';
import { getIfBasketContainsOnlyTestOrders } from '../../../../redux/selectors/basketSelector';
import {
  getIsCustomLoyaltyProgram,
  getIsKuchniaVikinga,
  getServerConfig,
} from '../../../../features/general/generalSlice';
import {
  burakLoyaltyProgramData,
  defaultLoyaltyProgramData,
} from '../../../../data/loyaltyProgramData';
import {
  useGetApiFormSettingsQuery,
  useGetApiLoyaltyProgramDetailsQuery,
  useGetApiParamsQuery,
} from '../../../../services/api/form/open/open.api';
import { useAppSelector } from '../../../../config/hooks';
import { profileApi } from '../../../../services/api/form/profile/profile.api';
import { Collapsible } from '../../../common/Collapsible/Collapsible';
import { clientPreferencesCheckboxesE } from '../../../../@types/profile/profile.enum';

type Props = { loggedInSummary?: boolean };

export const Agreements = ({ loggedInSummary }: Props) => {
  const dispatch = useDispatch();

  const { allowDietlyMarketingCheckbox, companyId, isCustomPanel } =
    useAppSelector(getServerConfig);

  const { data } =
    profileApi.endpoints.getApiProfileClient.useQueryState(undefined);

  const allowMarketingMessages = !!data?.allowMarketingMessages;

  const { data: formSettings } = useGetApiFormSettingsQuery(undefined, {
    skip: !companyId,
  });

  const summaryReducer = useAppSelector(getSummaryReducer);
  const profile = useAppSelector(getProfileDetails);

  const isKuchniaVikinga = useAppSelector(getIsKuchniaVikinga);

  const { allowMessages } = profile || {};

  const { generalInformation } = formSettings || {};

  const {
    allowCateringEmailMessages,
    allowCateringSMSMessages,
    marketingCatering,
    marketingDietly,
    newClientDetails: { willingToRegister },
    regulationsCatering,
    regulationsDietly,
  } = summaryReducer || {};

  const [isVisibleAllowCateringEmailMessages] = useState(
    !allowCateringEmailMessages
  );
  const [isVisibleAllowCateringSMSMessages] = useState(
    !allowCateringSMSMessages
  );

  const { data: params } = useGetApiParamsQuery(companyId, {
    skip: !companyId,
  });

  const doesBasketContainOnlyTestOrders = useAppSelector(
    getIfBasketContainsOnlyTestOrders
  );
  const isCustomLoyaltyProgram = useAppSelector(getIsCustomLoyaltyProgram);

  const loyaltyProgramData = isCustomLoyaltyProgram
    ? burakLoyaltyProgramData.agreements
    : defaultLoyaltyProgramData.agreements;

  const { data: loyaltyProgramRegulationDetails } =
    useGetApiLoyaltyProgramDetailsQuery(companyId, {
      skip: !companyId,
    });

  const getCheckAllState = (isLoggedInSummary?: boolean) => {
    switch (isLoggedInSummary) {
      case true:
        if (isCustomPanel) {
          return marketingCatering && regulationsCatering;
        }
        if (!isCustomPanel && allowDietlyMarketingCheckbox) {
          return marketingCatering && regulationsCatering && marketingDietly;
        }
        if (!isCustomPanel && !allowDietlyMarketingCheckbox) {
          return marketingCatering && regulationsCatering;
        }
        break;
      case false:
        if (isCustomPanel) {
          return marketingCatering && regulationsCatering;
        }
        if (!isCustomPanel && allowDietlyMarketingCheckbox) {
          return willingToRegister
            ? marketingCatering &&
                regulationsCatering &&
                regulationsDietly &&
                marketingDietly
            : marketingCatering && regulationsCatering;
        }
        if (!isCustomPanel && !allowDietlyMarketingCheckbox) {
          return willingToRegister
            ? marketingCatering && regulationsCatering && regulationsDietly
            : marketingCatering && regulationsCatering;
        }
        break;
      default:
        return false;
    }
  };

  const fields = [
    'marketingCatering',
    'regulationsCatering',
    (!allowMessages &&
      loggedInSummary &&
      !isCustomPanel &&
      allowDietlyMarketingCheckbox) ||
    (!loggedInSummary &&
      willingToRegister &&
      !isCustomPanel &&
      allowDietlyMarketingCheckbox)
      ? 'marketingDietly'
      : null,
    !loggedInSummary && willingToRegister && !isCustomPanel
      ? 'regulationsDietly'
      : null,
    isKuchniaVikinga && clientPreferencesCheckboxesE.allowCateringEmailMessages,
    isKuchniaVikinga && clientPreferencesCheckboxesE.allowCateringSMSMessages,
  ].filter(field => field !== null);

  return (
    <div className="padding-top-24 border-top-gray-200">
      <Checkbox
        checked={getCheckAllState(loggedInSummary)}
        containerClass="align-items-top spacer-bottom-16"
        id="checkAll"
        label={
          <span className="body-s">
            {`Zaznacz wszystko${
              willingToRegister &&
              params?.loyaltyProgramEnabled &&
              !doesBasketContainOnlyTestOrders
                ? loyaltyProgramData.text1
                : ''
            }`}
          </span>
        }
        name="checkAll"
        onChange={({ target: { checked } }) => {
          dispatch(
            toggleAllAgreementsCheckboxes(
              allowMarketingMessages
                ? fields.filter(item => item !== 'marketingCatering')
                : fields,
              checked
            )
          );
        }}
      />

      {!allowMessages &&
        loggedInSummary &&
        !isCustomPanel &&
        allowDietlyMarketingCheckbox && (
          <Checkbox
            checked={marketingDietly}
            containerClass="align-items-top spacer-bottom-16"
            id="marketingDietly"
            label={
              <span className="body-s">
                Wyrażam zgodę na kontakt marketingowy przez Dietly.pl
              </span>
            }
            name="marketingDietly"
            onChange={({ target: { checked, name } }) =>
              dispatch(toggleAgreementsCheckbox(name, checked))
            }
          />
        )}

      {!loggedInSummary &&
        willingToRegister &&
        !isCustomPanel &&
        allowDietlyMarketingCheckbox && (
          <Checkbox
            checked={marketingDietly}
            containerClass="align-items-top spacer-bottom-16"
            id="marketingDietly"
            label={
              <span className="body-s">
                Wyrażam zgodę na kontakt marketingowy przez Dietly.pl
              </span>
            }
            name="marketingDietly"
            onChange={({ target: { checked, name } }) =>
              dispatch(toggleAgreementsCheckbox(name, checked))
            }
          />
        )}

      {!allowMarketingMessages && !isKuchniaVikinga && (
        <Checkbox
          checked={marketingCatering}
          containerClass="align-items-top spacer-bottom-16"
          id="marketingCatering"
          label={
            generalInformation?.marketingAgreementUrl ? (
              <span className="body-s">
                Wyrażam zgodę na{' '}
                <a
                  className="underline font-weight-500"
                  href={generalInformation.marketingAgreementUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  kontakt marketingowy
                </a>{' '}
                przez {generalInformation?.cateringName}{' '}
              </span>
            ) : (
              <span className="body-s">
                Wyrażam zgodę na kontakt marketingowy przez{' '}
                {generalInformation?.cateringName}
              </span>
            )
          }
          name="marketingCatering"
          onChange={({ target: { checked, name } }) =>
            dispatch(toggleAgreementsCheckbox(name, checked))
          }
        />
      )}

      {isKuchniaVikinga && (
        <>
          {isVisibleAllowCateringEmailMessages && (
            <Checkbox
              checked={allowCateringEmailMessages}
              containerClass="align-items-top spacer-bottom-16"
              id={clientPreferencesCheckboxesE.allowCateringEmailMessages}
              label={
                <span className="body-s">
                  <Collapsible
                    bodyTextHidable={`Rozumiem, iż mogę wycofać wyrażoną zgodę w dowolnym momencie
                  zmieniając wybrane ustawienia w Panelu klienta lub kontaktując
                  się z Biurem Obsługi Klienta. Wycofanie zgody nie wpływa na
                  zgodność z prawem przetwarzania dokonanego przed wycofaniem
                  zgody.`}
                    bodyTextVisible={
                      <div>
                        Wyrażam zgodę na otrzymywanie informacji handlowych od{' '}
                        {generalInformation?.cateringName} na podany przeze mnie{' '}
                        <strong>adres e-mail</strong>.
                      </div>
                    }
                    headerText={['Pokaż więcej', 'Zwiń']}
                    isOpenAtStart={false}
                  />
                </span>
              }
              name={clientPreferencesCheckboxesE.allowCateringEmailMessages}
              onChange={({ target: { checked, name } }) =>
                dispatch(toggleAgreementsCheckbox(name, checked))
              }
            />
          )}
          {isVisibleAllowCateringSMSMessages && (
            <Checkbox
              checked={allowCateringSMSMessages}
              containerClass="align-items-top spacer-bottom-16"
              id={clientPreferencesCheckboxesE.allowCateringSMSMessages}
              label={
                <span className="body-s">
                  <Collapsible
                    bodyTextHidable={`Rozumiem, iż mogę wycofać wyrażoną zgodę w dowolnym momencie
                  zmieniając wybrane ustawienia w Panelu klienta lub kontaktując
                  się z Biurem Obsługi Klienta. Wycofanie zgody nie wpływa na
                  zgodność z prawem przetwarzania dokonanego przed wycofaniem
                  zgody.`}
                    bodyTextVisible={
                      <div>
                        Wyrażam zgodę na otrzymywanie informacji handlowych od{' '}
                        {generalInformation?.cateringName} na podany przeze mnie{' '}
                        <strong>numer telefonu</strong>.
                      </div>
                    }
                    headerText={['Pokaż więcej', 'Zwiń']}
                    isOpenAtStart={false}
                  />
                </span>
              }
              name={clientPreferencesCheckboxesE.allowCateringSMSMessages}
              onChange={({ target: { checked, name } }) =>
                dispatch(toggleAgreementsCheckbox(name, checked))
              }
            />
          )}
        </>
      )}

      {!loggedInSummary && willingToRegister && !isCustomPanel && (
        <Checkbox
          checked={regulationsDietly}
          containerClass="align-items-top spacer-bottom-16"
          id="regulationsDietly"
          label={
            <span className="body-s">
              <span className="color-error">*</span> Akceptuje{' '}
              <a
                className="underline font-weight-500"
                href="https://www.dietly.pl/regulamin-dietly"
                rel="noopener noreferrer"
                target="_blank"
              >
                regulamin
              </a>{' '}
              oraz{' '}
              <a
                className="underline font-weight-500"
                href="https://www.dietly.pl/polityka-prywatnosci"
                rel="noopener noreferrer"
                target="_blank"
              >
                politykę prywatności
              </a>{' '}
              serwisu Dietly.pl
            </span>
          }
          name="regulationsDietly"
          onChange={({ target: { checked, name } }) =>
            dispatch(toggleAgreementsCheckbox(name, checked))
          }
        />
      )}
      <Checkbox
        checked={regulationsCatering}
        containerClass="align-items-top spacer-bottom-24"
        id="regulationsCatering"
        label={
          <span className="body-s">
            <span className="color-error">*</span> Akceptuje{' '}
            <a
              className="underline font-weight-500"
              href={generalInformation?.termsOfUseUrl}
              rel="noopener noreferrer"
              target="_blank"
            >
              regulamin
            </a>
            {loyaltyProgramRegulationDetails && (
              <>
                {', '}
                <a
                  className="underline font-weight-500"
                  href={loyaltyProgramRegulationDetails.regulationUrl}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  regulamin programu lojalnościowego
                </a>
              </>
            )}{' '}
            oraz{' '}
            <a
              className="underline font-weight-500"
              href={generalInformation?.privacyPolicyUrl}
              rel="noopener noreferrer"
              target="_blank"
            >
              politykę prywatności
            </a>{' '}
            cateringu {generalInformation?.cateringName}
          </span>
        }
        name="regulationsCatering"
        onChange={({ target: { checked, name } }) =>
          dispatch(toggleAgreementsCheckbox(name, checked))
        }
      />
    </div>
  );
};
