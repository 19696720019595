// TODO: TYPES !!!!!!!!!!!!!
import { Dispatch } from 'redux';
import { customMap } from 'utilsRoot/index';
import {
  handleEcommerceData,
  handleEnhancedEcommerceData,
} from 'utilsRoot/enhanceEcommerce';
import { isFirefox, isSafari } from 'react-device-detect';
import { storePromoCode } from '@redux/actions/basketActions';
import { DeliveryMethodEnum } from '@features/pickupPoint/types/deliveryMethod.enum';
import { profileOrder } from '@features/orderForm/stepSummary/utils/profileOrder.util';
import { guestOrder } from '@features/orderForm/stepSummary/utils/guestOrder.util';
import { generateSelectedFees } from './generateSelectedFees.util';
import { NewProfileAddressT } from '@features/orderForm/stepSummary/types/stepSummary.type';
import { generateSimpleOrders } from './generateOrderData.util';
import {
  placeLoggedInOrderPdf,
  placeOpenOrderPdf,
} from '@redux/actions/profileActions';
import { getSummaryReducer } from '@redux/selectors/summaryReducer.selectors';
import { getBasketReducer } from '@redux/selectors/orderFormSelector';
import { getProfileDetails } from '@redux/selectors/profileSelector';
import { getPdfFile } from '@redux/selectors/basketSelector';
import {
  getIsKuchniaVikinga,
  getServerConfig,
} from '@features/general/generalSlice';
import { hashString } from 'utilsRoot/hashString.util';
import { MethodOfPurchaseEnum } from 'typesRoot/methodOfPurchase.enum';
import { ClientPreferencesE } from 'typesRoot/profile/profile.enum';
import { ProfileT } from 'typesRoot/profile/profile.type';
import { ProfileAddressWithSupportedCityT } from 'typesRoot/city/city.type';
import { SummaryReducerT } from 'typesRoot/summaryReducer.type';
import { GetStateT, NavigateT, SendDataToGTMT } from 'typesRoot/common.type';
import { getOneOffsSelectedFees } from '@features/orderForm/stepShoppingCart/redux/oneOffSideOrders.selector';
import Cookies from 'universal-cookie';
import { GUEST_COOKIE_NAME } from '@hooks/useGuestCookie.hooks';
import { CalculatePriceT } from 'typesRoot/calculatePrice.type';

export const displayOrderButton = (
  summaryReducer: any,
  isCustomPanel: boolean,
  login?: string
) => {
  const {
    deliveryMethod,
    newClientDetails: { willingToRegister },
    regulationsCatering,
    regulationsDietly,
    selectedProfileAddress,
  } = summaryReducer || {};

  if (login) {
    if (deliveryMethod === DeliveryMethodEnum.E) {
      return regulationsCatering && selectedProfileAddress !== 0;
    }
    return regulationsCatering;
  }

  if (!willingToRegister || isCustomPanel) {
    return regulationsCatering;
  }

  return regulationsDietly && regulationsCatering;
};
export const getProfileAddressesCitiesIds = (profileDetails: ProfileT) => {
  const { profileAddresses } = profileDetails || {};

  if (!profileAddresses) {
    return [];
  }

  return customMap('cityId')(profileAddresses)?.join(',') ?? [];
};

export const parseAddress = (address: ProfileAddressWithSupportedCityT) => {
  const { apartmentNumber, houseNumber, street, supportedCity } = address;

  const apartment = apartmentNumber ? `m. ${apartmentNumber}` : '';
  return `${street} ${houseNumber} ${apartment}, ${supportedCity?.name}`;
};

export const handleNewAddressObject = (
  summaryReducer: SummaryReducerT,
  deliveryMethod: DeliveryMethodEnum,
  profileAddressId: number | null
) => {
  const {
    methodOfPurchase,
    newAddressDetails: {
      apartmentNumber,
      buildingNumber,
      city: { cityId = 0 } = {},
      deliveryHour,
      floor,
      gate,
      gateCode,
      note,
      street,
      zipCode: { zipCodeId = 0 } = {},
    },
  } = summaryReducer || {};

  if (
    methodOfPurchase === MethodOfPurchaseEnum.Login &&
    deliveryMethod !== DeliveryMethodEnum.P
  ) {
    return null;
  }

  if (deliveryMethod === DeliveryMethodEnum.P && profileAddressId) {
    return null;
  }

  return {
    billingAddress: false,
    deliverySpot: 'DOOR',
    street,
    houseNumber: buildingNumber,
    apartmentNumber,
    gateKey: gateCode,
    floor,
    staircase: gate,
    hourPreference: deliveryHour,
    info: note,
    cityId,
    zipCodeId,
  };
};

export const handlePlacePanelOrder: any =
  (
    sendDataToGTM: SendDataToGTMT,
    navigate: NavigateT,
    prices: CalculatePriceT
  ) =>
  (dispatch: Dispatch, getState: GetStateT) => {
    const summaryReducer = getSummaryReducer(getState()) || {};
    const basketReducer = getBasketReducer(getState()) || {};
    const profileDetails = getProfileDetails(getState()) || {};
    const selectedFees = getOneOffsSelectedFees(getState() || {});
    const { file, hasFile } = getPdfFile(getState()) || {};
    const config = getServerConfig(getState()) || {};
    const isKuchniaVikinga = getIsKuchniaVikinga(getState() || {});

    const {
      allowCateringEmailMessages,
      allowCateringSMSMessages,
      deliveryMethod,
      marketingCatering,
      marketingDietly,
      methodOfPurchase,
      // TODO: TYPES
      // @ts-expect-error
      newAddressDetails: { city, deliveryHour, note } = {},

      newClientDetails: {
        // TODO: TYPES
        // @ts-expect-error
        company,
        // TODO: TYPES
        // @ts-expect-error
        companyApartmentNumber,
        // TODO: TYPES
        // @ts-expect-error
        companyBuildingNumber,
        // TODO: TYPES
        // @ts-expect-error
        companyCity,
        // TODO: TYPES
        // @ts-expect-error
        companyName,
        // TODO: TYPES
        // @ts-expect-error
        companyStreet,
        // TODO: TYPES
        // @ts-expect-error
        companyZipCode,
        // TODO: TYPES
        // @ts-expect-error
        email,
        // TODO: TYPES
        // @ts-expect-error
        name,
        // TODO: TYPES
        // @ts-expect-error
        password,
        // TODO: TYPES
        // @ts-expect-error
        phone: phoneNumberWithoutPrefix,
        // TODO: TYPES
        // @ts-expect-error
        phoneNumberPrefix,
        // TODO: TYPES
        // @ts-expect-error
        surname,
        // TODO: TYPES
        // @ts-expect-error
        taxId,
        // TODO: TYPES
        // @ts-expect-error
        willingToRegister,
      } = {},
      originId,
      paymentType,
      pickupPointId,
      selectedProfileAddress,
    } = summaryReducer;

    const phone = `${phoneNumberPrefix}${phoneNumberWithoutPrefix}`;

    const {
      basket,
      // TODO: TYPES
      // @ts-expect-error
      loyaltyPoints: { usedLoyaltyPoints } = {},
      // TODO: TYPES
      // @ts-expect-error
      promoCode: { code } = {},
    } = basketReducer || {};

    const {
      analytics: { googleTagManager },
      companyId,
      enhancedEcommerce,
      pdfUpload,
    } = config;

    const { allowMessages, firstName } = profileDetails || {};

    const { cart: { totalCostToPay = 0 } = {} } = prices || {};
    const loyaltyPointsForRegistration =
      calculateLoyaltyPointsForRegistration(totalCostToPay);

    const newProfile = {
      firstName: name,
      lastName: surname,
      email,
      phoneNumber: phone,
      company,
      companyName: companyName,
      taxId,
    };

    const simpleOrders = generateSimpleOrders(
      {
        deliveryMethod,
        pickupPointId,
        methodOfPurchase,
        selectedProfileAddress,
        deliveryHour,
        company,
        note,
        paymentType,
      },
      basket
    );

    generateSelectedFees(selectedFees, simpleOrders);

    const invoiceData = company
      ? {
          companyName,
          taxId: taxId,
          cityName: companyCity,
          zipCode: companyZipCode,
          street: companyStreet,
          apartmentNumber: companyApartmentNumber,
          houseNumber: companyBuildingNumber,
        }
      : null;

    const newProfileAddress: NewProfileAddressT | null = handleNewAddressObject(
      summaryReducer,
      deliveryMethod,
      selectedProfileAddress?.profileAddressId
    );

    let order = {
      allowCateringMessages: marketingCatering,
      clientPreferences: isKuchniaVikinga
        ? [
            {
              type: ClientPreferencesE.ALLOW_MARKETING_EMAIL_CONTACT,
              permitted: allowCateringEmailMessages,
            },
            {
              type: ClientPreferencesE.ALLOW_MARKETING_PHONE_CONTACT,
              permitted: allowCateringSMSMessages,
            },
          ]
        : [],
      lang: 'pl',
      paymentRedirectUrl: {
        defaultUrl: `${window.location.href.split('#')[0]}#/status_platnosci`,
        failureUrl: `${window.location.href.split('#')[0]}#/wystapil_problem`,
        successUrl: `${window.location.href.split('#')[0]}#/zaplacono`,
      },
      promoCode: code ? code.code : '',
      newProfileAddress,
      simpleOrders,
      invoiceData,
    };

    if (
      methodOfPurchase === MethodOfPurchaseEnum.Guest ||
      methodOfPurchase === MethodOfPurchaseEnum.Register
    ) {
      order = {
        ...order,
        // TODO: TYPES
        // @ts-expect-error
        originId: originId !== 0 ? originId : null,
        newProfile,
        signUp: !!(
          methodOfPurchase === MethodOfPurchaseEnum.Register ||
          willingToRegister
        ),
        allowDietlyMessages: marketingDietly,
      };
    }

    // add condition that profile exists
    if (methodOfPurchase === MethodOfPurchaseEnum.Login && firstName) {
      if (!allowMessages) {
        order = {
          ...order,
          // TODO: TYPES
          // @ts-expect-error
          allowDietlyMessages: marketingDietly,
        };
      }
      order = {
        ...order,
        // TODO: TYPES
        // @ts-expect-error
        loyaltyProgramPoints: usedLoyaltyPoints,
      };
    }

    if (
      methodOfPurchase === MethodOfPurchaseEnum.Register ||
      willingToRegister
    ) {
      if (enhancedEcommerce && googleTagManager) {
        sendDataToGTM({
          event: 'GAEvent',
          eventCategory: MethodOfPurchaseEnum.Login,
          eventAction: 'Login success',
          eventLabel: 'N/A',
        });
      }

      order = {
        ...order,
        // TODO: TYPES
        // @ts-expect-error
        newPassword: password,
        allowDietlyMessages: marketingDietly,
      };
    }

    const { orderId } = basket[0];

    sessionStorage.removeItem('order-form');
    sessionStorage.removeItem('loyaltyProgramPoints');

    const successPageData = {
      methodOfPurchase,
      name: name ? name : firstName,
      surname,
      email,
      phone,
      companyName,
      taxId,
      orderId,
      willingToRegister,
      companyId,
      company,
      loyaltyPointsForRegistration,
      paymentType,
      marketingDietly,
    };
    const successPageDataStringified = JSON.stringify(successPageData);
    sessionStorage.setItem(
      'success-page-data-form',
      successPageDataStringified
    );

    // TODO: ANY
    const sendGTMTransactionEvents = async (
      orderIds: any,
      previousOrdersNumber: any,
      clientId: number,
      shoppingCartId = '',
      clientLifecycleState: string,
      email: string,
      crm_transaction_id?: string
    ) => {
      const hashedEmail = await hashString(email);

      if (googleTagManager) {
        enhancedEcommerce
          ? sendDataToGTM({
              event: 'transaction',
              ...handleEnhancedEcommerceData(
                basket,
                firstName,
                totalCostToPay,
                paymentType,
                city,
                code,
                orderIds,
                previousOrdersNumber,
                clientId,
                companyId,
                shoppingCartId,
                clientLifecycleState,
                hashedEmail,
                crm_transaction_id
              ),
            })
          : sendDataToGTM({
              event: 'transaction',
              ...handleEcommerceData(basket, totalCostToPay, city),
            });
      }
    };

    if (methodOfPurchase === MethodOfPurchaseEnum.Login) {
      if (pdfUpload && hasFile) {
        dispatch(
          //TODO: TYPES
          //@ts-expect-error
          placeLoggedInOrderPdf(order, file, navigate, sendGTMTransactionEvents)
        );
        return;
      }
      // TODO: TYPES
      // @ts-expect-error
      dispatch(profileOrder(order, navigate, sendGTMTransactionEvents, prices));
      return;
    }

    if (pdfUpload && hasFile) {
      dispatch(
        // TODO: TYPES
        // @ts-expect-error
        placeOpenOrderPdf(order, file, navigate, sendGTMTransactionEvents)
      );
      return;
    }
    // TODO: TYPES
    // @ts-expect-error
    dispatch(guestOrder(order, navigate, sendGTMTransactionEvents, prices));
    return;
  };

export const redirectToLoginPage = async (
  // TODO: TYPES
  // @ts-expect-error
  config,
  isThirdCookiesEnabled: boolean
) => {
  const { companyId, panelUrl, thirdPartyCookies } = config || {};
  const cookie = new Cookies();

  if (thirdPartyCookies && (isSafari || isFirefox || isThirdCookiesEnabled)) {
    return window.open(
      `${panelUrl}/logowanie?dataFromForm=true&company=${companyId}&guestSession=${cookie.get(
        GUEST_COOKIE_NAME
      )}`,
      '_self'
    );
  }

  return window.open(
    `${panelUrl}/logowanie-catering?company=${companyId}&redirectUrl=${window.location.href}`,
    '_self'
  );
};

// TODO: TYPES
// @ts-expect-error
export const savePromoCodeInTheLocalStorage = promoCode => {
  if (!promoCode.code) {
    return;
  }
  return localStorage.setItem(
    'basket-promo-code-form',
    JSON.stringify(promoCode)
  );
};

export const checkIfContinueParam = () => {
  const href = window.location.href;
  const params = href.split('?')[1];
  if (params && params !== '') {
    const paramsObject = params.split('&').reduce((res, item) => {
      const parts = item.split('=');
      // TODO: TYPES
      // @ts-expect-error
      res[parts[0]] = parts[1];
      return res;
    }, {});

    // TODO: TYPES
    // @ts-expect-error
    return paramsObject.continue;
  }
  return false;
};

export const retrieveDataIfContinueParam = () => (dispatch: Dispatch) => {
  if (checkIfContinueParam()) {
    const promoCode = localStorage.getItem('basket-promo-code-form');
    const parsedPromoCode = promoCode && JSON.parse(promoCode);

    if (parsedPromoCode && parsedPromoCode.code) {
      Promise.resolve(dispatch(storePromoCode(parsedPromoCode.code))).then(() =>
        localStorage.removeItem('basket-promo-code-form')
      );
    }
  }
};

export const parseDeliveryTime = (
  deliveryTime: Array<{
    timeFrom: string;
    timeTo: string;
  }>
): { name: string }[] => {
  const sliceValue = (value: string) => value?.slice(0, 5);

  return deliveryTime.map(item => {
    const { timeFrom, timeTo } = item;

    if (timeFrom === '23:59:59') {
      return {
        name: `do ${sliceValue(timeTo)}`,
      };
    }

    if (timeFrom === timeTo) {
      return {
        name: `${sliceValue(timeTo)}`,
      };
    }

    return {
      name: timeFrom
        ? `od ${sliceValue(timeFrom)} do ${sliceValue(timeTo)}`
        : `do ${sliceValue(timeFrom)}`,
    };
  });
};

export const calculateLoyaltyPointsForRegistration = (
  totalCostToPay: number
) => {
  if (!totalCostToPay) {
    return 0;
  }
  const points = Math.floor(totalCostToPay / 50) * 50;
  return isNaN(points) ? 0 : points;
};
